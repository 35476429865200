import Vue from 'vue'
import App from './index.vue'
import router from './router'
import store from '@/store/'
import entryConfig from '@/common/entryConfig/'

// 调用公共方法加载配置
entryConfig(Vue)
//全局的配置
import '@/modules/config'

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
