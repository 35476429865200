import Vue from "vue";
import Router from "vue-router";
// 首页
const Index = (resolve) => {
  require.ensure(["../views/index.vue"], () => {
    resolve(require("../views/index.vue"));
  });
};

Vue.use(Router);

let base = `${process.env.BASE_URL}`;

export default new Router({
  mode: "history",
  base: base,
  routes: [
    {
      path: "/",
      name: "Index",
      component: Index,
      meta: {
        title: "首页",
      },
    },
  ],
});
