<!--
 * @Description:推荐产品
 -->
<template>
  <div
    class="qytx-list"
    v-infinite-scroll="loadMore"
    infinite-scroll-distance="10"
    :infinite-scroll-disabled="loadBusy"
  >
    <div
      class="recom-card"
      v-for="(item, index) in dataList"
      :key="item.id"
      @click="toGameDetail(item.id)"
    >
      <!-- <router-link :to="{ path: '/gamedetails', query: { id: item.id } }"> -->
      <div class="recom-card__banner">
        <div class="video-player-card__image">
          <img
            class="video-hls__poster-img"
            v-lazy="imgBaseUrl + item.pic"
            alt=""
          />
        </div>
      </div>
      <!-- </router-link> -->
      <!-- <router-link :to="{ path: '/gamedetails', query: { id: item.id } }"> -->
      <div class="recom-card__contents">
        <div class="recom-card__ranking" v-if="index <= 10">
          <div class="rank-text">{{ parseInt(index + 1) }}</div>
        </div>
        <div class="recom-card__contents-main">
          <div class="recom-card__games-icon">
            <img v-lazy="imgBaseUrl + item.icon" alt="" />
          </div>
          <div class="recom-card__games-rbox">
            <div class="caption-m10-w25 overflow1 black-02">
              {{ item.name }}
            </div>
            <p class="caption-m10-w15 gray-06 games-vendor">
              厂商:{{ item.distributor_name }}
            </p>
            <div class="game-score">
              <el-rate
                class="game-rate-content"
                v-model="item.score"
                disabled
                show-score
                :colors="[
                  '#09a7ce',
                  '#09a7ce',
                  '#09a7ce',
                  '#09a7ce',
                  '#09a7ce',
                ]"
                disabled-void-color="#CBCBCB"
                text-color="#CBCBCB"
                score-template="{value}"
              >
              </el-rate>
            </div>
            <div class="game-desc gray-06 overflow3">{{ item.desc }}</div>
            <div class="game-tags">
              <div
                class="game-tags-item"
                v-for="tag in item.class_name"
                :key="tag.id"
              >
                {{ tag.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- </router-link> -->
    </div>
    <div class="nodata" v-if="!hasData">暂无数据</div>
    <div class="loading" v-show="loading">
      <img width="20" height="20" src="@/assets/images/loadmore.gif" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "RecomProducts",
  props: {
    dataList: {
      type: Array,
      default() {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadMore: {
      type: Function,
    },

    loadBusy: {
      type: Boolean,
      default: false,
    },
    hasData: {
      type: Boolean,
      default: true,
    },
  },
  computed: {},
  data() {
    return {
      imgBaseUrl: process.env.VUE_APP_IMG_BASE_URL, //图片根地址
    };
  },
  methods: {
    toGameDetail(id) {
      this.$openRouter({
        name: "game_detail.html", // 跳转地址
        query: { id: id },
      });
    },
  },
};
</script>

<style lang="less">
@import "../styles/recomProducts.less";
</style>
