<template>
  <div class="app-wrapper" :class="classObj">
    <div class="main-container">
      <left-nav :isCollapse="device == 'mobile' ? true : false" />
      <home></home>
    </div>
    <footer-nav></footer-nav>
    <BackToTop />
  </div>
</template>

<script>
import { LeftNav, FooterNav } from "@/layout/components";
import Home from "@/pages/index/views/index";
import ResizeMixin from "@/layout/mixin/ResizeHandler";
export default {
  name: "Layout",
  components: {
    Home,
    LeftNav,
    FooterNav,
  },
  mixins: [ResizeMixin],
  computed: {
    sidebar() {
      return this.$store.state.app.sidebar;
    },
    device() {
      return this.$store.state.app.device;
    },
    fixedHeader() {
      return this.$store.state.settings.fixedHeader;
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile",
      };
    },
  },
  created() {
    console.log(this.device, "created");
  },
  updated() {
    console.log(this.device, "updated");
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch("app/closeSideBar", { withoutAnimation: false });
    },
  },
};
</script>

<style lang="less" scoped>
.app-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  background: #f5f7f8;
  .main-container {
    display: flex;
    max-width: 1400px;
    margin: auto;
    justify-content: space-between;
  }
  @media only screen and (max-width: 1220px) {
    .main-container {
      max-width: 1200px;
    }
  }
  @media only screen and (max-width: 466px) {
    .main-container {
      padding-bottom: 60px;
    }
  }
  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}
.hideSidebar .fixed-header {
  width: calc(100% - 54px);
}

.mobile .fixed-header {
  width: 100%;
}
</style>
