var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"qytx__main"},[_c('transition',{attrs:{"name":"fade-transform","mode":"out-in"}},[_c('div',{staticClass:"qytx__main-body"},[_c('qytx-main-header',{attrs:{"search-type":0}}),_c('div',{staticClass:"qytx__main-warp"},[_c('div',{staticClass:"qytx__main-container"},[_c('div',{staticClass:"qytx__tabs"},[_c('el-tabs',{on:{"tab-click":_vm.qytxHandleClick},model:{value:(_vm.qytxHomeActive),callback:function ($$v) {_vm.qytxHomeActive=$$v},expression:"qytxHomeActive"}},_vm._l((_vm.qytxHomeTabs),function(item){return _c('el-tab-pane',{key:item.index,attrs:{"label":item.name,"name":item.index}})}),1)],1),(_vm.qytxHomeActive == 0)?_c('div',{staticClass:"qytx__tab-pane"},[_c('new-products',{attrs:{"data-list":_vm.qytxHomeTabs.length
                  ? _vm.qytxHomeTabs[_vm.qytxHomeActive].tabsList
                  : [],"loading":_vm.qytxHomeTabs.length
                  ? _vm.qytxHomeTabs[_vm.qytxHomeActive].loading
                  : false,"load-busy":_vm.loadBusy,"load-more":_vm.loadMore,"has-data":_vm.hasData}})],1):_vm._e(),(_vm.qytxHomeActive == 1)?_c('div',{staticClass:"qytx__tab-pane"},[_c('recom-products',{attrs:{"data-list":_vm.qytxHomeTabs.length
                  ? _vm.qytxHomeTabs[_vm.qytxHomeActive].tabsList
                  : [],"loading":_vm.qytxHomeTabs.length
                  ? _vm.qytxHomeTabs[_vm.qytxHomeActive].loading
                  : false,"load-busy":_vm.loadBusy,"load-more":_vm.loadMore,"has-data":_vm.hasData}})],1):_vm._e()]),_c('aside',{staticClass:"qytx__aside-container"},[_c('div',{staticClass:"qytx__app-side"},[_c('carousel-side'),_c('hot-game-side'),_c('qytx-footer-side')],1)])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }