<!--
 * @Description:最新产品
 -->
<template>
  <div
    class="qytx-list"
    v-infinite-scroll="loadMore"
    infinite-scroll-distance="10"
    :infinite-scroll-disabled="loadBusy"
  >
    <div
      class="feed-card"
      v-for="(item, index) in dataList"
      :key="index"
      :id="'feedId' + item.id"
      @click="toGameDetail(item.id)"
    >
      <!-- <router-link :to="{ path: '/gamedetails', query: { id: item.id } }"> -->
      <div class="feed-card__banner">
        <div class="video-player-card__image feed-video-card">
          <img
            class="video-hls__poster-img"
            v-if="!item.isPlay"
            v-lazy="imgBaseUrl + item.pic"
            alt=""
          />
          <div class="common-new-video" v-else>
            <video
              :id="'myVideo' + item.id"
              class="video-js"
              muted
              loop
              preload="false"
            >
              <source :src="imgBaseUrl + item.video" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
      <!-- </router-link> -->
      <!-- <router-link :to="{ path: '/gamedetails', query: { id: item.id } }"> -->
      <div class="feed-card__contents">
        <div class="feed-card__contents-main">
          <div class="feed-card__key-points" v-if="item.recommend">
            <span class="feed-card__key-point">
              <svg-icon icon-class="like"></svg-icon>
              <span>编辑推荐</span>
            </span>
          </div>
          <div class="feed-card__title-box ">
            <span class="feed-card__title-text">{{ item.name }}</span>
          </div>
          <div class="caption-m12-w14 overflow2">{{ item.desc }}</div>
        </div>
        <div class="feed-card__contents-aside">
          <div class="feed-card__rating">
            <div class="feed-card__rating-rate--pc">
              {{ item.score }}
            </div>
          </div>
        </div>
      </div>
      <!-- </router-link> -->
    </div>
    <div class="nodata" v-if="!hasData">暂无数据</div>
    <div class="loading" v-show="loading">
      <img width="20" height="20" src="@/assets/images/loadmore.gif" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "NewProducts",
  props: {
    dataList: {
      type: Array,
      default() {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadMore: {
      type: Function,
    },

    loadBusy: {
      type: Boolean,
      default: true,
    },
    hasData: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      imgBaseUrl: process.env.VUE_APP_IMG_BASE_URL, //图片根地址
    };
  },

  computed: {},
  methods: {
    toGameDetail(id) {
      this.$openRouter({
        name: "game_detail.html", // 跳转地址
        query: { id: id },
      });
    },
  },
};
</script>

<style lang="less">
@import "../styles/newProducts.less";
</style>
