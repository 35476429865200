<template>
  <main class="qytx__main">
    <transition name="fade-transform" mode="out-in">
      <div class="qytx__main-body">
        <qytx-main-header :search-type="0"></qytx-main-header>
        <div class="qytx__main-warp">
          <div class="qytx__main-container">
            <div class="qytx__tabs">
              <el-tabs v-model="qytxHomeActive" @tab-click="qytxHandleClick">
                <el-tab-pane
                  v-for="item in qytxHomeTabs"
                  :key="item.index"
                  :label="item.name"
                  :name="item.index"
                >
                </el-tab-pane>
              </el-tabs>
            </div>
            <div class="qytx__tab-pane" v-if="qytxHomeActive == 0">
              <new-products
                :data-list="
                  qytxHomeTabs.length
                    ? qytxHomeTabs[qytxHomeActive].tabsList
                    : []
                "
                :loading="
                  qytxHomeTabs.length
                    ? qytxHomeTabs[qytxHomeActive].loading
                    : false
                "
                :load-busy="loadBusy"
                :load-more="loadMore"
                :has-data="hasData"
              ></new-products>
            </div>
            <div class="qytx__tab-pane" v-if="qytxHomeActive == 1">
              <recom-products
                :data-list="
                  qytxHomeTabs.length
                    ? qytxHomeTabs[qytxHomeActive].tabsList
                    : []
                "
                :loading="
                  qytxHomeTabs.length
                    ? qytxHomeTabs[qytxHomeActive].loading
                    : false
                "
                :load-busy="loadBusy"
                :load-more="loadMore"
                :has-data="hasData"
              ></recom-products>
            </div>
          </div>
          <aside class="qytx__aside-container">
            <div class="qytx__app-side">
              <carousel-side></carousel-side>
              <hot-game-side></hot-game-side>
              <qytx-footer-side></qytx-footer-side>
            </div>
          </aside>
        </div>
      </div>
    </transition>
  </main>
</template>

<script>
import { gameData } from "@/api/index";
import { getTagsData } from "@/api/common";
import NewProducts from "../module/newProducts";
import RecomProducts from "../module/recomProducts";
export default {
  name: "Home",
  components: {
    NewProducts,
    RecomProducts,
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { vmid: "description", name: "description", content: this.description },
        { vmid: "keywords", name: "description", content: this.seo_keywords },
      ],
    };
  },
  computed: {
    device() {
      return this.$store.getters.device;
    },
    hasData() {
      if (this.qytxHomeTabs && this.qytxHomeTabs[this.qytxHomeActive]) {
        const tabsList = this.qytxHomeTabs[this.qytxHomeActive].tabsList;
        if (tabsList) {
          setTimeout(() => {
            return tabsList.length ? true : false;
          }, 200);
        }
      }
    },
  },
  data() {
    const fn = this._.debounce(this.scrollToArea, 300);
    return {
      imgBaseUrl: process.env.VUE_APP_IMG_BASE_URL, //图片根地址
      title:
        "113手游网-好玩的仙侠传奇塔防休闲手游免费下载-手游攻略资讯尽在113gm.com",
      seo_keywords:
        "手机游戏下载，游戏排行榜，热门手游、精品仙侠、传奇、宫斗、休闲手游推荐，安卓手游下载，苹果游戏下载，游戏攻略",
      description:
        " 113游戏网为玩家提供最新最热门最流行手机游戏下载，以及各类精品仙侠传奇宫斗休闲手游推荐、新闻资讯、评测、攻略、游戏礼包、新游动态等最新信息，113GM每天为广大手游用户提供安卓、ios手机游戏推荐、下载等，是您查找手游的最佳选择，更多游戏尽在113GM网！",
      qytxHomeActive: "0",
      qytxHomeTabs: [],
      loadBusy: false,
      debounceFn: fn,
    };
  },

  created() {
    getTagsData("index").then((res) => {
      res.map((item, index) => {
        item.index = index.toString();
        item.tabsList = [];
        item.page = 1;
        item.loading = false;
        item.isRequest = false;
      });
      this.$set(this, "qytxHomeTabs", res);
      this.getList();
    });
  },
  mounted() {
    window.addEventListener("scroll", this.debounceFn);
  },

  methods: {
    // 加载滚动
    loadMore() {
      let loading = this.qytxHomeTabs.length
        ? this.qytxHomeTabs[this.qytxHomeActive].loading
        : true;
      if (loading) return;
      if (this.qytxHomeTabs.length) {
        let isRequest = this.qytxHomeTabs.length
          ? this.qytxHomeTabs[this.qytxHomeActive].isRequest
          : true; //true 不请求 false 请求
        if (!isRequest) {
          this.getList();
        }
      }
    },
    // 获取列表
    async getList() {
      this.loadBusy = true;
      this.$set(this.qytxHomeTabs[this.qytxHomeActive], "loading", true);
      let listQuery = {
        tag: this.qytxHomeTabs[this.qytxHomeActive].id,
        pageNum: this.qytxHomeTabs[this.qytxHomeActive].page,
        total: 0, // 总条数
        pageSize: 6,
      };
      const res = await gameData(listQuery);
      if (res.code === 200) {
        let result = res.data;
        if (result.length) {
          result.map((item) => {
            item.isPlay = false;
            item.score = parseInt(item.score / 2);
          });
          var originalData = this.qytxHomeTabs[this.qytxHomeActive].tabsList;
          this.$set(
            this.qytxHomeTabs[this.qytxHomeActive],
            "tabsList",
            originalData.concat(result)
          );
          this.qytxHomeTabs[this.qytxHomeActive].page += 1;
          this.$set(this.qytxHomeTabs[this.qytxHomeActive], "loading", false);
          this.$set(this.qytxHomeTabs[this.qytxHomeActive], "isRequest", false);
          if (listQuery.pageNum == 1 && this.qytxHomeActive == 0) {
            this.qytxHomeTabs[this.qytxHomeActive].tabsList[0].isPlay = true;
            let first_id = this.qytxHomeTabs[this.qytxHomeActive].tabsList[0]
              .id;
            setTimeout(() => {
              document.getElementById("myVideo" + first_id).play();
            }, 200);
          }
        } else {
          //没有数据 不再请求
          this.$set(this.qytxHomeTabs[this.qytxHomeActive], "isRequest", true);
          this.$set(this.qytxHomeTabs[this.qytxHomeActive], "loading", false);
        }
      }
      this.loadBusy = false;
    },
    qytxHandleClick(tab) {
      this.$set(this, "qytxHomeActive", tab.index);
      if (tab.index == 1) {
        window.removeEventListener("scroll", this.debounceFn);
      } else {
        window.addEventListener("scroll", this.debounceFn);
      }
      let loading = this.qytxHomeTabs[this.qytxHomeActive];
      if (loading) return;
      this.getList();
    },
    scrollToArea() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop; // 滚动条偏移量
      // 获取视窗高度
      var palyerDom;
      //监听滚动后元素距离顶部距离
      var tabsList = this.qytxHomeTabs[this.qytxHomeActive].tabsList;
      if (scrollTop == 0) {
        tabsList.map((item) => {
          item.isPlay = false;
        });
        tabsList[0].isPlay = true;
        setTimeout(() => {
          palyerDom = document.getElementById("myVideo" + tabsList[0].id);
          if (palyerDom) {
            palyerDom.play();
          }
        }, 200);
        return;
      }
      let observer = new IntersectionObserver((entries) => {
        entries.map((item, index) => {
          if (item.intersectionRatio === 1) {
            tabsList[index].isPlay = tabsList[index].video ? true : false;
          } else {
            tabsList[index].isPlay = false;
          }
        });
      });
      tabsList.map((item) => {
        observer.observe(document.getElementById(`feedId${item.id}`));
        if (item.isPlay && item.video != "") {
          palyerDom = document.getElementById(`myVideo${item.id}`);
          palyerDom.play();
        } else {
          item.isPlay = false;
        }
      });
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.debounceFn);
  },
};
</script>

<style lang="less" scoped>
@import "../styles/index.less";
</style>
